import EventBus from './EventBus';
import DataCollectorBindings from './DataCollectorBindings';

class DataCollector {

    constructor() {
        this.initBindings();
    }

    initBindings() {
        const bindings = DataCollectorBindings.bindings;
        Object.keys(bindings).forEach(function(event) {
            EventBus.$on(event, bindings[event].bind(event));
        });
    }

}

export default DataCollector;
