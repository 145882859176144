import HttpRequest from '../utils/HttpRequest';

const defaultConfig = {
    url: '/api/availability?year={year}&month={month}',
    headers: [],
    params: {
        productIds: []
    }
};

class AvailabilityService {

    constructor(config = {}) {
        this.config = Object.assign(Object.create(defaultConfig), config);
        this.cache = [];
        this.request = null;
    }

    getAvailability(params, successCallback, errorCallback) {
        if(this.request) {
            this.request.abort();
        }

        const url = AvailabilityService.getInterpolatedUrl(this.config.url, params.year, params.month, this.config.params);

        this.request = new HttpRequest('GET', url, this.config.headers);
        this.request.fetch(successCallback, errorCallback);
    }

    static getInterpolatedUrl(url, year, month, params) {
        let paramsString = AvailabilityService.getParamsString(params.productIds);
        let interpolatedUrl = url.replace('{year}', year).replace('{month}', month);
        return `${interpolatedUrl}${paramsString}`;
    }

    static getParamsString(params) {
        let paramsString = '';
        params.forEach(param => paramsString += `&productId=${param.id}`);
        return paramsString;
    }
}

export default AvailabilityService;
