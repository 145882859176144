export const stringFilter = {
    filters: {
        camelize: function(str) {
            return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(letter, index) {
                return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
            }).replace(/\s+/g, '');
        },

        /**
         * Return "true" or "false" as a string.
         * i.e. To use in an HTML5 Aria attribute
         *
         * @param str
         * @returns {*}
         */
        booleanString: function(str) {
            if(typeof str !== 'boolean') {
                return '';
            }
            return str.toString();
        }
    }
}
