import Vue from 'vue';
import Viewport from '../../utils/Viewport';
import raf from 'raf';

Vue.component('sdw-sticky', {
    props: {
        bodyClass: {
            type: String,
            default: 'has-sticky-footer'
        },
        matchBreakpoint: false,
        testAboveViewport: {
            type: Boolean,
            default: true
        },
        testBelowViewport: {
            type: Boolean,
            default: false
        },
        offsetTop: {
            type: Number,
            default: 0
        },
        offsetBottom: {
            type: Number,
            default: 0
        },
        target: null
    },
    data: function() {
        return {
            isSticky: false,
            viewport: false,
            lastXY: []
        };
    },
    mounted() {
        if (!this.target) {
            this.show(); // Always show if no target is provided
            return;
        }

        // We have a target, so get it from the DOM
        this.targetEl = this.target ? document.querySelector(this.target) : null;

        // Bound method
        this.loopBound = this.loop.bind(this);

        // Kick in on ready
        const onReadyState = function onReadyState() {
            if (typeof document !== 'undefined') {
                switch (document.readyState) {
                    case 'loading':
                    case 'interactive':
                        break;
                    case 'complete':
                        this.loop();
                        document.removeEventListener('readystatechange', onReadyState);
                        break;
                    default:
                        break;
                }
            }
        }.bind(this);

        document.addEventListener('readystatechange', onReadyState, false);

        // Apply listeners on resize
        const onResize = function onResize() {
            this.viewport = Viewport.getViewportSize();
        };

        window.addEventListener('resize', onResize, false);

        // Lift-off!
        this.loop();
    },
    methods: {
        show() {
            this.isSticky = true;
            document.body.classList.add(this.bodyClass);
        },
        hide() {
            this.isSticky = false;
            document.body.classList.remove(this.bodyClass);
        },
        loop() {
            if (this.lastXY.join() === Viewport.getScroll().join()) {
                // Avoid calculations if not needed
                raf(this.loopBound);
                return false;
            } else {
                this.lastXY = Viewport.getScroll();

                let targetDimensions = Viewport.offset(this.targetEl);

                let node = {
                    top: targetDimensions.top + this.offsetTop,
                    bottom: targetDimensions.top + this.offsetBottom + targetDimensions.height
                };

                let viewport = {
                    top: this.lastXY[1],
                    bottom: this.lastXY[1] + this.viewport.h
                };

                const isAboveViewport = node.top < viewport.top;
                const isBelowViewport = node.bottom > viewport.bottom;

                const testAboveViewport = (this.testAboveViewport && isAboveViewport);
                const testBelowViewport = (this.testBelowViewport && isBelowViewport);
                const isFullyInViewport = (node.top >= viewport.top && node.bottom <= viewport.bottom) || (isAboveViewport && isBelowViewport);

                if(!isFullyInViewport && (testAboveViewport || testBelowViewport)) {
                    this.show();
                } else {
                    this.hide();
                }
            }

            raf(this.loopBound);
        }
    }
});
