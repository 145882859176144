export const SELECT_DATE = 'SELECT_DATE';
export const SET_DAY_WITH_FOCUS = 'SET_DAY_WITH_FOCUS';

export const FETCH_AVAILABILITY_REQUEST = 'FETCH_AVAILABILITY_REQUEST';
export const FETCH_AVAILABILITY_SUCCESS = 'FETCH_AVAILABILITY_SUCCESS';
export const FETCH_AVAILABILITY_FAILURE = 'FETCH_AVAILABILITY_FAILURE';

export const CART_SET_ITEMS = 'CART_SET_ITEMS';
export const CART_SET_NUMBER_OF_NIGHTS = 'CART_SET_NUMBER_OF_NIGHTS';
export const CART_INCREMENT_QUANTITY = 'CART_INCREMENT_QUANTITY';
export const CART_DECREMENT_QUANTITY = 'CART_DECREMENT_QUANTITY';
export const CART_RESET_QUANTITY = 'CART_RESET_QUANTITY';
export const CART_UPDATE_AVAILABILITY = 'CART_UPDATE_AVAILABILITY';
export const CART_UPDATE_ITEM_AVAILABILITY = 'CART_UPDATE_ITEM_AVAILABILITY';
