import Responsive from '../utils/Responsive';

const defaultConfig = {
    matchBreakpoint: null
};

class Component {

    /**
     * @constructor
     *
     * @param {String, HTMLElement} el - The element or node to attach to
     * @param {Object} config - The configuration object for the Vue instance
     */
    constructor(el = '', config = {}) {
        this.$el = el;
        this.config = Object.assign({}, defaultConfig, config);
    }

    get matchBreakpoint() {
        return Responsive.matchBreakpoint(this.config.matchBreakpoint);
    }
}

export default Component;
