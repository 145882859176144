export default function setTemporaryFocus(element, focusOptions = {}) {

    const originalTabIndex = element.getAttribute('tabindex') || '';
    element.setAttribute('tabindex', '-1');
    element.focus(focusOptions);
    element.addEventListener('blur', reset);

    function reset() {
        element.removeEventListener('blur', reset);
        if(originalTabIndex) {
            element.setAttribute('tabindex', originalTabIndex);
        } else {
            element.removeAttribute('tabindex');
        }
    }
}
