import AvailabiltyService from '../../services/AvailabilityService';
import * as types from '../types';
import DateUtils from '../../utils/DateUtils';

// initial state
const state = {
    availableDays: [],
    dayWithFocus: null,
    selectedDate: null,
    isFetching: null,
    fetchFailed: null,
    fetchSuccess: null
};

// actions
const actions = {
    selectDate({commit, state}, date) {
        if (state.selectedDate !== date) {
            commit(types.SELECT_DATE, {date});
        }
    },
    setDayWithFocus({commit}, day, trigger) {
        commit(types.SET_DAY_WITH_FOCUS, {day, trigger});
    },
    retryFetch({commit, rootState}, args) {
        const {params, apiConfig} = args;
        const availabilityService = new AvailabiltyService(apiConfig);

        commit(types.FETCH_AVAILABILITY_REQUEST, {params});

        availabilityService.getAvailability(
            params,
            (data) => commit(types.FETCH_AVAILABILITY_SUCCESS, {data, params, rootState}),
            (data, status) => {
                const errorMessage = 'Error retrieving availability';
                if (typeof Sentry !== 'undefined') {
                    try {
                        Sentry.withScope(scope => {
                            scope.setExtras({xhrData: data, xhrStatus: status, xhrParams: params});
                            Sentry.captureMessage(errorMessage, Sentry.Severity.Error);
                        });
                    } catch (e) {
                        //ignore
                    }
                }
                if (typeof DD_LOGS !== 'undefined') {
                    try {
                        DD_LOGS.logger.error(errorMessage,
                            {
                                // eslint-disable-next-line camelcase
                                xhr_data: data, xhr_status: status, xhr_params: params
                            }, new Error(errorMessage));
                    } catch (e) {
                        //ignore
                    }
                }
                if (typeof DD_RUM !== 'undefined') {
                    try {
                        DD_RUM.addError(new Error(errorMessage));
                    } catch (e) {
                        //Ignore
                    }
                }
                commit(types.FETCH_AVAILABILITY_FAILURE);
            }
        );
    }
};

// mutations
const mutations = {
    [types.SELECT_DATE](state, payload) {
        state.selectedDate = payload.date;
        state.dayWithFocus = payload.date ? payload.date.getDate() : null;
    },
    [types.SET_DAY_WITH_FOCUS](state, payload) {
        state.dayWithFocus = payload.day;
    },
    [types.FETCH_AVAILABILITY_REQUEST](state) { // eslint-disable-line no-unused-vars
        state.isFetching = true;
        state.fetchFailed = null;
        state.fetchSuccess = null;
    },
    [types.FETCH_AVAILABILITY_SUCCESS](state, payload) {
        state.isFetching = false;
        try {
            const {data, params, rootState} = payload;
            const {year, month} = params;
            const result = JSON.parse(data);

            const transformedResult = {};

            // First filter available items by modulo of stepsize
            const productIds = rootState.cart.items;

            if (result && result.availability) {
                for (let date in result.availability) {
                    const products = result.availability[date];

                    const availableProducts = products.filter(availableProduct => {
                        const filterProduct = productIds.filter(product => {
                            return (availableProduct.id === product.id) && (availableProduct.quantity >= product.step);
                        });
                        return filterProduct.length;
                    });

                    if (availableProducts.length) {
                        transformedResult[date] = availableProducts;
                    }
                }
            }

            let monthAvailability = [];
            monthAvailability[year] = state.availableDays[year] || [];
            monthAvailability[year][month] = transformedResult;

            state.availableDays = Object.assign({}, state.availableDays, monthAvailability);
            state.fetchFailed = false;
            state.fetchSuccess = true;
        } catch (error) {
            state.fetchFailed = true;
            state.fetchSuccess = false;
            state.dayWithFocus = null;
            try {
                Sentry.captureException(error);
            } catch (e) {
                //ignore
            }
        }
    },

    [types.FETCH_AVAILABILITY_FAILURE](state) {
        state.isFetching = false;
        state.fetchFailed = true;
        state.fetchSuccess = false;
        state.dayWithFocus = null;
    }
};

// getters
const getters = {
    availabilityByDate: (state) => (date) => {

        const yyyy = date.getFullYear();
        const dd = DateUtils.getFullDigits(date.getDate());
        const mm = DateUtils.getFullMonthDigits(date);

        const dateString = `${yyyy}-${mm}-${dd}`;

        const yearData = state.availableDays[yyyy];

        if (typeof yearData !== 'undefined') {
            const monthData = yearData[date.getMonth() + 1];

            if (typeof monthData !== 'undefined') {
                if (Object.keys(monthData).indexOf(dateString) >= 0) {
                    return monthData[dateString];
                }
            }
        }

        return [];
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
