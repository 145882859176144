class DisableSvgFocus {
    constructor() {
        if(!document.implementation.hasFeature('http://www.w3.org/TR/SVG2/feature#GraphicsAttribute', 2.0)) {
            const svgElements = document.getElementsByTagName('svg');
            for(let i = 0; i < svgElements.length; i++) {
                svgElements[i].setAttribute('focusable', 'false');
            }
        }
    }
}

export default new DisableSvgFocus();
