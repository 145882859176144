import publishTagmanagement from './publishTagmanagement';

class DataCollectorFunctions {

    static trackChat() {
        const eventName = 'open';
        const eventCategory = 'userinteractie/chat/open';
        const eventData = {};
        publishTagmanagement(eventCategory, eventName, eventData);
    }

    static trackMapsMarkerCluster(data) {
        const eventName = 'clusterclick';
        const eventCategory = 'userinteractie/maps';
        const eventData = data;
        publishTagmanagement(eventCategory, eventName, eventData);
    }

    static trackDatePicker(data) {
        const eventName = this.split(':').slice(-1).pop();
        const eventCategory = `userinteractie/datepicker/${eventName}`;
        const eventData = data;
        publishTagmanagement(eventCategory, eventName, eventData);
    }

    static trackMapsMarker(data) {
        const eventName = 'open';
        const eventCategory = 'userinteractie/maps';
        const eventData = data;
        publishTagmanagement(eventCategory, eventName, eventData);
    }

    static trackMapLink(data) {
        const eventName = 'maplink';
        const eventCategory = 'userinteractie/maps';
        const eventData = data;
        publishTagmanagement(eventCategory, eventName, eventData);
    }

    static trackEventData(data) {
        const eventName = data.eventname || data.event;
        const eventCategory = data.category;
        const eventData = data.data;
        publishTagmanagement(eventCategory, eventName, eventData);
    }

    static trackCollapsible($event, data) {
        const binding = this;
        const eventName = binding.toLowerCase().indexOf('expand') !== -1 ? 'expand' : 'collapse';
        const eventCategory = `userinteractie/collapsible/${eventName}`;
        const eventData = {
            trigger: $event.currentTarget.textContent.trim(),
            data: data
        };
        publishTagmanagement(eventCategory, eventName, eventData);

    }

    static trackContentSelector(data) {
        const eventName = 'select';
        const eventCategory = 'userinteractie/contentselector';
        const eventData = {
            from: data.oldVal,
            to: data.newVal
        };
        publishTagmanagement(eventCategory, eventName, eventData);

    }

    static trackMainMenu(open, source) {
        const eventName = open ? 'open' : 'close';
        const eventCategory = 'userinteractie/header/' + eventName;
        const eventData = {
            module: 'menu',
            source: source
        };
        publishTagmanagement(eventCategory, eventName, eventData);

    }

    static trackProductCartConfigurator(item, modifier, items) {
        const stripItem = function(item) {
            return {
                id: item.id,
                name: item.name,
                price: item.price,
                quantity: item.quantity
            }
        };
        const eventName = 'changeQuantity';
        const eventCategory = 'userinteractie/productCart/changeQuantity';
        const eventData = {
            trigger: stripItem(item),
            modifier: modifier,
            items: items.map((item) => stripItem(item))
        };
        publishTagmanagement(eventCategory, eventName, eventData);
    }

    static trackProductCartSubmit(data) {
        const eventName = 'click';
        const eventCategory = 'userinteractie/productCart/submit';
        const eventData = {
            trigger: data.trigger,
            data: data.productInfo
        };
        publishTagmanagement(eventCategory, eventName, eventData);
    }

    static trackSliderChange(data) {
        const eventName = 'click';
        const eventCategory = 'userinteractie/slider/changeSlide';
        const eventData = data;
        publishTagmanagement(eventCategory, eventName, eventData);

    }

    static trackSliderReset(data) {
        const eventName = 'resize';
        const eventCategory = 'userinteractie/slider/reset';
        const eventData = data;
        publishTagmanagement(eventCategory, eventName, eventData);
    }

    static trackSmoothScroll(data) {
        const eventName = 'scroll';
        const eventCategory = 'userinteractie/clicks/anchors';
        const eventData = data;
        publishTagmanagement(eventCategory, eventName, eventData);
    }

    static trackProductListing(data) {
        const eventName = 'sort';
        const eventCategory = 'userinteractie/sortselector';
        const eventData = {
            from: data.oldVal,
            to: data.newVal
        };
        publishTagmanagement(eventCategory, eventName, eventData);
    }

    static trackNightSelector(data) {
        const eventName = 'select';
        const eventCategory = 'userinteractie/nightselector';
        const eventData = {
            from: data.oldVal,
            to: data.newVal
        };
        publishTagmanagement(eventCategory, eventName, eventData);

    }
}

export default DataCollectorFunctions;
