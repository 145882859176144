class Viewport {

    static offset(element) {
        const rect = element.getBoundingClientRect();
        const docEl = document.documentElement;
        return {
            left: rect.left + window.pageXOffset - docEl.clientLeft,
            top: rect.top + window.pageYOffset - docEl.clientTop,
            width: element.offsetWidth,
            height: element.offsetHeight
        };
    }

    static getScroll() {
        return [
            window.pageXOffset
            || (document.documentElement && document.documentElement.scrollLeft)
            || document.body.scrollLeft,
            window.pageYOffset
            || (document.documentElement && document.documentElement.scrollTop)
            || document.body.scrollTop
        ];
    }

    static getViewportSize() {
        return {
            w: window.innerWidth || document.documentElement.clientWidth,
            h: window.innerHeight || document.documentElement.clientHeight
        };
    }

    static getDocumentHeight() {
        return Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.documentElement.clientHeight
        );
    }
}

export default Viewport;
