import Vue from 'vue';
import Vuex from 'vuex';

import CartStore from './modules/cart';
import DatepickerStore from './modules/datepicker';

// Use Vuex
Vue.use(Vuex);

export const store = new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    state: {},
    modules: {
        'cart': CartStore,
        'datepicker': DatepickerStore
    }
});
