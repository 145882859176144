export default function publishTagmanagement(category, eventname, data) {
    data = typeof data === 'object' ? data : {};

    // Send events to DDQ
    if (typeof window.nsDataQueue === 'object' && typeof category !== 'string' && typeof eventname === 'string') {
        var ddqData = Object.assign({
            event: eventname
        }, data);

        window.nsDataQueue.push(ddqData);
    }
}
