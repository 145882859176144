import Viewport from './Viewport';
import setTemporaryFocus from './setTemporaryFocus';
import gsap from 'gsap/gsap-core';
import TweenLite from 'gsap/gsap-core';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

export default function scrollToTarget(target, options = {}, callBack) {

    const animationTime = (typeof options.animationTime !== 'undefined' && options.animationTime !== null) ? options.animationTime : 0.5;
    const scrollMargin = (typeof options.scrollMargin !== 'undefined' && options.scrollMargin !== null) ? options.scrollMargin : 28;
    let focus = (typeof options.focus !== 'undefined' && options.focus !== null) ? options.focus : true;

    let targetPos;

    //checks if target is a number or element
    if (typeof target === 'number') {
        targetPos = target;
        focus = false;
    } else {
        targetPos = Viewport.offset(target).top - scrollMargin;
    }

    targetPos = targetPos >= 0 ? targetPos : 0;
    if (window.scrollTop !== targetPos) {
        TweenLite.to(window, animationTime, {
            scrollTo: {
                y: targetPos,
                autoKill: false
            },
            ease: TweenLite.defaultEase,
            onComplete: () => {
                if (callBack && typeof callBack === 'function') {
                    callBack();
                }
                if (focus) {
                    setTemporaryFocus(target, {
                        preventScroll: true
                    });
                }
            }

        });
    } else {
        if (callBack && typeof callBack === 'function') {
            callBack();
        }
        if (focus) {
            setTemporaryFocus(target, {
                preventScroll: true
            });
        }
    }
}
