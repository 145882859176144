import Component from '../base/Component';
import EventBus from '../utils/EventBus';

class EventDataObject extends Component {

    constructor(el = '', config = {}) {

        super(el, config);

        this.init();
    }

    init() {
        this.setListeners();
    }

    trackClick() {
        const eventDataString = this.$el.getAttribute('data-eventdata');
        if(eventDataString) {
            try {
                EventBus.$emit('EventDataObject:click', JSON.parse(eventDataString));
            }
            catch(e) {
                console.error('Event data invalid for:', eventDataString, e);
            }
        }
    }

    trackToggle() {
        const eventDataString = this.$el.getAttribute('data-eventdata');
        if(eventDataString) {
            try {
                var eventName;
                if (this.$el.getAttribute('open') !== null) {
                    eventName = 'Collapsible:onExpand';
                } else {
                    eventName = 'Collapsible:onCollapse';
                }
                EventBus.$emit(eventName, {currentTarget: this.$el.firstChild}, JSON.parse(eventDataString));
            }
            catch(e) {
                console.error('Event data invalid for:', eventDataString, e);
            }
        }
    }

    setListeners() {
        if (this.$el.nodeName === 'A' || this.$el.nodeName === 'BUTTON') {
            this.$el.addEventListener('click', this.trackClick.bind(this), false);
        } else if (this.$el.nodeName === 'DETAILS') {
            this.$el.addEventListener('toggle', this.trackToggle.bind(this), false);
        }
    }
}

export default EventDataObject;
