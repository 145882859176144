import {DAY_LABELS_SHORT_NL, MONTH_LABELS_SHORT_NL} from './Constants';

class DateUtils {

    static testMinDate(date, minDate) {
        return typeof date !== 'object' || typeof minDate !== 'object' || date.setHours(0,0,0,0) >= minDate.setHours(0,0,0,0);
    }

    static testMaxDate(date, minDate) {
        return typeof date !== 'object' || typeof minDate !== 'object' || date.setHours(0,0,0,0) <= minDate.setHours(0,0,0,0);
    }

    static getFullDigits(number) {
        return (number < 10) ? '0' + number : number;
    }

    static getFullDayDigits(day) {
        return (day < 10) ? '0' + day : day;
    }

    static getFullMonthDigits(date) {
        if(typeof date === 'object') {
            let mm = date.getMonth() + 1;
            return (mm < 10) ? '0' + mm : mm;
        }
    }


    static getDateFromString(date) {
        if(typeof date === 'string') {
            const splitDate = date.split('-');
            if(splitDate.length === 3) {
                splitDate[1]--;
                return new Date(...splitDate);
            }
        }
    }

    static getStringFromDate(date) {
        if(typeof date === 'object') {
            let month = date.getMonth() + 1;
            month = month >= 10 ? month : `0${month}`;
            let day = date.getDate();
            day = day >= 10 ? day : `0${day}`;
            return `${date.getFullYear()}-${month}-${day}`;
        }
    }

    static getFormattedStringFromDate(date) {
        if(typeof date === 'object') {
            let month = date.getMonth() + 1;
            month = month >= 10 ? month : `0${month}`;
            let day = date.getDate();
            day = day >= 10 ? day : `0${day}`;
            return `${day}-${month}-${date.getFullYear()}`;
        }
    }

    static getAlphanumericStringFromDate(date) {
        if(typeof date === 'object') {
            let month = date.getMonth();
            month = MONTH_LABELS_SHORT_NL[month];
            let day = date.getDate();
            //order of day labels differs from order of getDay function.
            let weekday = date.getDay() - 1;
            if(weekday === -1) {
                weekday = 6;
            }
            weekday = DAY_LABELS_SHORT_NL[weekday];
            return `${weekday} ${day} ${month} ${date.getFullYear()}`;
        }
    }
}

export default DateUtils;
