/**
    Global constants can be stored in this module
 **/

// Animation properties
export const DEFAULT_ANIMATION_SPEED = 300;

// Date strings and properties
export const DAYS_IN_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
export const DAY_LABELS_SHORT_NL = ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];
export const DAY_LABELS_LONG_NL = ['maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag', 'zondag'];
export const MONTH_LABELS_SHORT_NL = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
export const MONTH_LABELS_LONG_NL = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];
