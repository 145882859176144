import debounce from 'debounce';
import EventBus from './EventBus';
import Viewport from './Viewport';

class Responsive {

    /**
     * Static breakpoint list
     * @returns {*}
     * @private
     */
    static get _breakpoints() {
        return {
            s: 16,
            sm: 480,
            m: 640,
            ml: 768,
            l: 912,
            xl: 1024,
            xxl: 1200,
            xxxl: 1408
        }
    }

    /**
     * Retrieve the breakpoint string
     * @returns {string}: String name of breakpoint
     */
    static get breakpoint() {
        const vw = Viewport.getViewportSize().w;
        const breakpointIndex = Object.keys(Responsive._breakpoints);
        let match = breakpointIndex.pop();
        breakpointIndex.some(function(breakpoint, index) {
            if(Responsive._breakpoints[breakpoint] > vw) {
                match = breakpointIndex[index-1];
                return true;
            }
        });
        return match;
    }

    /**
     * Test if user is inside of a breakpoint
     * @param breakpoint: Breakpoint string
     * @returns {boolean}
     */
    static isInsideBreakpoint(breakpoint) {
        const vw = Viewport.getViewportSize().w;
        return vw < Responsive._breakpoints[breakpoint];
    }

    /**
     * Provide a breakpoint string, and test if user is inside that breakpoint or not
     * @param breakpoint: Breakpoint string; start with ! to invert the condition (e.g. '!m')
     * @returns {boolean}
     */
    static respondMin(breakpoint) {
        if(!breakpoint) {
            return true;
        } else if(breakpoint[0] === '!') {
            return !Responsive.isInsideBreakpoint(breakpoint.substr(1));
        } else {
            return Responsive.isInsideBreakpoint(breakpoint);
        }
    }

    static onResize() {
        EventBus.$emit('Responsive:onResize');
        const currentBreakpoint = Responsive.breakpoint;
        const lastBreakpoint = Responsive.lastBreakpoint;
        if(currentBreakpoint !== lastBreakpoint) {
            EventBus.$emit('Responsive:onBreakpointChange', {lastBreakpoint, currentBreakpoint});
            Responsive.lastBreakpoint = currentBreakpoint;
        }
    }

    static onOrientationChange() {
        Responsive.onResize();
    }
}

window.onresize = debounce(Responsive.onResize, 150);
window.addEventListener('orientationchange', Responsive.onOrientationChange);

export default Responsive;
