/**
 * Spoordeelwinkel.nl
 *
 * Main bundle
 *
 */

import Vue from 'vue';
// import Debug from 'debug';
import EventBus from './utils/EventBus';
import ComponentManager from './utils/ComponentManager';
import DataCollector from './utils/DataCollector';
import Viewport from './utils/Viewport';
import EventDataObject from './components/EventDataObject';
import './store';
import './components/vue';
import './utils/polyfills';

export function main() {
    const _formatComponentName = function(vm) {
        if (vm.$root === vm) {
            return 'root instance';
        }
        const name = vm._isVue ? vm.$options.name || vm.$options._componentTag : vm.name;
        return (
            (name ? `component <${name}>` : 'anonymous component') +
            (vm._isVue && vm.$options.__file ? ` at ${vm.$options.__file}` : '')
        );
    };
    Vue.config.errorHandler = function(err, vm, info) {
        //Based on: https://github.com/getsentry/sentry-javascript/blob/68945a17d653b9c8d1ca58db1a46b6ae3107aaa0/packages/integrations/src/vue.ts
        const errorMessage = 'Unhandled error';
        const metadata = {};
        try {
            if (typeof vm !== 'undefined') {
                metadata.componentName = _formatComponentName(vm);
                metadata.propsData = vm.$options.propsData;
            }
            if (typeof info !== 'undefined') {
                metadata.lifecycleHook = info;
            }
        } catch (e) {
            //ignore
        }
        if (typeof Sentry !== 'undefined') {
            try {
                // This timeout makes sure that any breadcrumbs are recorded before sending it off the sentry
                setTimeout(() => {
                    Sentry.withScope(scope => {
                        scope.setContext('vue', metadata);
                        Sentry.captureException(err);
                    });
                });
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(errorMessage, err);
            }
        }
        if (typeof DD_LOGS !== 'undefined') {
            try {
                DD_LOGS.logger.error(errorMessage,
                    {
                        'vue': metadata
                    }, err);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(errorMessage, err);
            }
        }
        if (typeof DD_RUM !== 'undefined') {
            try {
                DD_RUM.addError(err);
            } catch (e) {
                //Ignore
            }
        }
        if (
            typeof Sentry === 'undefined' &&
            typeof DD_LOGS === 'undefined' &&
            typeof DD_RUM === 'undefined'
        ) {
            if (typeof console !== 'undefined') {
                // eslint-disable-next-line no-console
                console.error(errorMessage, err);
            } else {
                throw err;
            }
        }
    };

    // Initialize DataCollector
    new DataCollector();

    const vueInstance = new Vue({
        name: 'Sdw',
        el: '#sdw',
        delimiters: ['${', '}'],
        comments: true
    });

    const isEditMode = function() {
        return document.body !== null ? document.body.getAttribute('data-editmode') === 'true' : false;
    };

    const listenToDOMChanges = function() {
        const target = vueInstance.$el;
        const registeredComponents = Object.keys(Vue.options.components);
        const hasVueComponent = function(node) {
            return registeredComponents.indexOf(node.nodeName.toLowerCase()) >= 0 || Array.prototype.some.call(node.childNodes, hasVueComponent);
        };

        const getVueComponentRoots = function(node) {
            const result = [];
            node.childNodes.forEach((childNode) => {
                if (hasVueComponent(childNode)) {
                    result.push(childNode);
                }
            });
            return result;
        };
        const observer = new MutationObserver(function(mutations) {
            mutations.forEach(function(mutation) {
                mutation.addedNodes.forEach((node) => {
                    // We cannot simply use the node as root because brXM depends on a certain structure of the DOM (comments)
                    // and Vue messes this up.
                    const vueRootNodes = getVueComponentRoots(node);
                    vueRootNodes.forEach((vueRootNode) => {
                        initializeVueComponents(vueRootNode);
                    });
                });
            });
        });
        observer.observe(target, { childList: true, subtree: true });
    };

    const initializeVueComponents = function(node) {
        new Vue(
            {
                parent: vueInstance,
                el: node,
                comments: vueInstance.$options.comments,
                delimiters: vueInstance.$options.delimiters
            });
    };

    // Disable the console.log productionTip, we've got this covered already
    Vue.config.productionTip = false;

    // Expose debug to window, so we can enable debug mode from the console
    // window.debug = Debug;

    // Expose Spoordeelwinkel Event Bus to window, so we can subscribe from 3rd party scripts
    const SpoordeelApp = {
        componentManager: new ComponentManager(),
        events: EventBus,
        initialized: true,
        initializeVueComponents: initializeVueComponents
    };

    if (isEditMode()) {
        listenToDOMChanges();
    }

    Array.from(document.querySelectorAll('[data-eventdata]')).forEach(function(element) {
        new EventDataObject(element);
    });

    window.SpoordeelApp = SpoordeelApp;

    // viewportSize alias For NS test automation
    window.viewportSize = window.viewportSize || {};
    window.viewportSize.getWidth = () => Viewport.getViewportSize().w;
    window.viewportSize.getHeight = () => Viewport.getViewportSize().h;
}
