import Component from '../base/Component';
import scrollToTarget from '../utils/ScrollToTarget';
import EventBus from '../utils/EventBus';

const defaultConfig = {
    recordState: true
};

class SmoothScroll extends Component {


    constructor(el = '', config = {}) {
        super(el, Object.assign({}, defaultConfig, config));
        this.init();
    }

    init() {
        this.hash = this.$el.hash ? this.$el.hash.slice(1) : '';
        if(!this.hash || this.hash.length <= 1) {
            return;
        }

        this.$el.addEventListener('click', this.scroll.bind(this));
    }

    scroll(event) {
        const hash = this.hash;
        const target = document.getElementById(hash);
        if(target) {
            event.preventDefault();
            EventBus.$emit('SmoothScroll:onScrollInit', {from: this.$el, to: target});
            if(this.config.recordState && window.location.hash !== '#' + hash) {
                history.pushState({}, '', '#' + hash);
            }
            scrollToTarget(
                target,
                {
                    animationTime: (typeof this.config.animationTime !== 'undefined') ? this.config.animationTime : 1,
                    scrollMargin: (typeof this.config.scrollMargin !== 'undefined') ? this.config.scrollMargin : null
                },
                () => {
                    EventBus.$emit('SmoothScroll:onScrollComplete', {from: this.$el, to: target});
                }
            );
        }
    }

}

export default SmoothScroll;
