import DataCollectorFunctions from './DataCollectorFunctions';

class DataCollectorBindings {

    static get bindings() {
        return {
            'Chat:open': DataCollectorFunctions.trackChat,
            'Maps:open': DataCollectorFunctions.trackMapsMarker,
            'Maps:cluster': DataCollectorFunctions.trackMapsMarkerCluster,
            'MapLink:scrollStart': DataCollectorFunctions.trackMapLink,
            'DatePicker:changeMonth': DataCollectorFunctions.trackDatePicker,
            'DatePicker:selectDay': DataCollectorFunctions.trackDatePicker,
            'EventDataObject:click': DataCollectorFunctions.trackEventData,
            'MainMenu:open': DataCollectorFunctions.trackMainMenu,
            'MainMenu:close': DataCollectorFunctions.trackMainMenu,
            'Collapsible:onExpand': DataCollectorFunctions.trackCollapsible,
            'Collapsible:onCollapse': DataCollectorFunctions.trackCollapsible,
            'ContentSelector:onSelect': DataCollectorFunctions.trackContentSelector,
            'ProductCartConfigurator:changeQuantity': DataCollectorFunctions.trackProductCartConfigurator,
            'ProductListing:onSort': DataCollectorFunctions.trackProductListing,
            'ShoppingCart:onSubmit': DataCollectorFunctions.trackProductCartSubmit,
            'Slider:changeSlide': DataCollectorFunctions.trackSliderChange,
            'Slider:reset': DataCollectorFunctions.trackSliderReset,
            'SmoothScroll:onScrollComplete': DataCollectorFunctions.trackSmoothScroll,
            'NightSelector:onSelect': DataCollectorFunctions.trackNightSelector
        };
    }
}

export default DataCollectorBindings;
