import Vue from 'vue';
import EventBus from '../../utils/EventBus';
import Responsive from '../../utils/Responsive';
import setTemporaryFocus from '../../utils/setTemporaryFocus';
import {stringFilter} from '../../filters';

Vue.component('sdw-main-menu', {
    props: {
        config: {}
    },
    data: function() {
        return {
            respondMin: this.config.respondMin,
            isActive: false,
            isOpen: false,
            self: this
        };
    },
    computed: {
        menuIcon() {
            return this.isOpen ? '#close' : '#menu'
        }
    },
    methods: {
        toggle(source) {
            this.source = source || 'toggle';
            this.isOpen = !this.isOpen;
        },
        close(source) {
            this.source = source || 'toggle';
            this.isOpen = false;
            setTemporaryFocus(this.$refs.menuButton);
        },
        resetFocus(event, direction) {

            if(!this.isOpen) {
                return;
            }

            const relatedTarget = event.relatedTarget;
            if(!relatedTarget) {
                return;
            }

            const focusOutsideMenu = !(relatedTarget.classList.contains('navigation__item') || relatedTarget.classList.contains('navigation__button'));
            if(!focusOutsideMenu) {
                return;
            }

            event.preventDefault();

            if(direction === -1) {
                setTemporaryFocus(this.$refs.lastLink);
            } else if(Responsive.isInsideBreakpoint('l')) {
                setTemporaryFocus(this.$refs.menuButton);
            }
        },
        afterEnter(el) {
            if (this.isOpen) {
                el.classList.add('is-open');
                this.$refs.firstLink.focus();
            }
        },
        beforeLeave(el) {
            el.classList.remove('is-open');
        }
    },
    mixins: [
        stringFilter
    ],
    watch: {
        isOpen(newVal) {
            if(newVal) {
                EventBus.$emit('MainMenu:open', true, this.source);
            } else {
                EventBus.$emit('MainMenu:close', false, this.source);
            }
        },
        isActive(newVal) {
            if(!newVal) {
                this.isOpen = false;
            }
        }
    },
    created() {
        this.isActive = Responsive.respondMin(this.respondMin);
        EventBus.$on('Responsive:onBreakpointChange', () => {
            const respondMin = Responsive.respondMin(this.respondMin);
            if(this.isActive && !respondMin || !this.isActive && respondMin) {
                this.isActive = !this.isActive;
            }
        });
    }
});
