import Vue from 'vue';

Vue.component('sdw-darkener', {
    props: {
        active: false,
        onClick: Function
    },
    template: '<transition name="fade" v-on:enter="enter" v-on:after-leave="afterLeave">' +
    '<div v-if="active" v-bind:class="classObject" v-on:click="onClick"></div>' +
    '</transition>',
    computed: {
        classObject: function() {
            return {
                'darkener': true,
                'is-visible': this.active
            }
        }
    },
    watch: {
        active(newValue) {
            if(newValue === true) {
                this.$emit('onShow');
            } else {
                this.$emit('onHide');
            }
        }
    },
    methods: {
        enter() {
            document.body.classList.add('no-scroll');
        },
        afterLeave() {
            document.body.classList.remove('no-scroll');
        }
    }
});
