class HttpRequest {

    constructor(method, url, headers) {
        if(method && url) {
            this.method = method;
            this.url = url;
            this.headers = headers;
        }
    }

    fetch(successCallback, errorCallback) {
        this.asyncRequest = new XMLHttpRequest();
        this.asyncRequest.onreadystatechange = () => this.handleResponse(successCallback, errorCallback);

        this.asyncRequest.open(this.method, this.url, true);

        this.asyncRequest.setRequestHeader('Accept', 'application/json');
        if(this.headers) {
            this.headers.forEach(header => {this.asyncRequest.setRequestHeader(header.name, header.value)});
        }
        this.asyncRequest.send();
    }

    handleResponse(successCallback, errorCallback) {
        const request = this.asyncRequest;
        const readyState = request.readyState;
        if(readyState !== 4) {
            return;
        }

        const status = request.status;
        const data = request.responseText;
        switch(status) {
            case 200:
                successCallback(data);
                break;
            default:
                errorCallback(data, status);
                break;
        }
    }

    abort() {
        if(this.asyncRequest) {
            this.asyncRequest.abort();
        }
    }
}

export default HttpRequest;
