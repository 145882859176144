import * as types from '../types';
import Vue from 'vue';

const state = {
    items: [],
    numberOfNights: 0
};

const actions = {
    setItems({commit}, items) {
        commit(types.CART_SET_ITEMS, {items});
    },
    setNumberOfNights({commit}, numberOfNights) {
        commit(types.CART_SET_NUMBER_OF_NIGHTS, {numberOfNights});
    },
    incrementQuantity({commit}, index) {
        commit(types.CART_INCREMENT_QUANTITY, {index});
        commit(types.CART_UPDATE_ITEM_AVAILABILITY);
    },
    decrementQuantity({commit}, index) {
        commit(types.CART_DECREMENT_QUANTITY, {index});
        commit(types.CART_UPDATE_ITEM_AVAILABILITY);
    },
    updateAvailability({commit}, availableItems) {
        commit(types.CART_UPDATE_AVAILABILITY, {availableItems});
        commit(types.CART_RESET_QUANTITY);
        commit(types.CART_UPDATE_ITEM_AVAILABILITY);
    }
};


const mutations = {
    [types.CART_SET_ITEMS](state, payload) {
        state.items = payload.items;
    },
    [types.CART_SET_NUMBER_OF_NIGHTS](state, payload) {
        state.numberOfNights = payload.numberOfNights
    },
    [types.CART_INCREMENT_QUANTITY](state, payload) {
        const {index} = payload;
        const item = state.items[index];
        item.quantity += item.step;
    },
    [types.CART_DECREMENT_QUANTITY](state, payload) {
        const {index} = payload;
        const item = state.items[index];
        item.quantity -= item.step;
    },
    [types.CART_RESET_QUANTITY](state) {
        state.items.forEach(item => item.quantity = 0);
    },
    [types.CART_UPDATE_AVAILABILITY](state, payload) {
        state.items.forEach(item => {
            const match = payload.availableItems.filter(availableItem => {
                return item.id === availableItem.id;
            });
            Vue.set(item, 'available', !!match.length);
            const availableQuantity = match.length ? match[0].quantity : 0;
            Vue.set(item, 'availableQuantity', availableQuantity);
        });
    },
    [types.CART_UPDATE_ITEM_AVAILABILITY](state) {
        state.items.forEach(item => {
            if(typeof item.availableQuantity !== 'undefined') {
                Vue.set(item, 'moreAvailable', item.availableQuantity >= (item.quantity + item.step));
            }
        });
    }
};

export default {
    actions,
    state,
    mutations
}

