import Vue from 'vue';
/**
 * Non async component definitions
 *
 * These components will be bundled with the main entry point bundle. No
 * extra request will be made for these components. Useful for common
 * components that are used across the application.
 */
import './Darkener';
import './MainMenu';
import './Sticky';

/**
 * Vue component definitions
 *
 * All components here are async components using this setup:
 * https://vuejs.org/v2/guide/components.html#Async-Components
 */

/**
 * Async component definitions
 *
 * We define here all our async components that will be exported as
 * chunks. Useful for components that are only used on single pages.
 */
Vue.component('sdw-carousel', () => import('./Carousel'));
Vue.component('sdw-collapsible', () => import('./Collapsible'));
Vue.component('sdw-content-selector', () => import('./ContentSelector'));
Vue.component('sdw-datepicker', () => import('./DatePicker'));
Vue.component('sdw-hotel-datepicker', () => import('./HotelDatePicker'));
Vue.component('sdw-expander', () => import('./Expander'));
Vue.component('sdw-map', () => import('./Map'));
Vue.component('sdw-map-link', () => import('./MapLink'));
Vue.component('sdw-nights-selector', () => import('./NightSelector'));
Vue.component('sdw-product-cart-configurator', () => import('./ProductCartConfigurator'));
Vue.component('sdw-hotel-product-cart-configurator', () => import('./HotelProductCartConfigurator'));
Vue.component('sdw-product-listing', () => import('./ProductListing'));
Vue.component('sdw-shopping-cart', () => import('./ShoppingCart'));
Vue.component('sdw-slider', () => import('./Slider'));


