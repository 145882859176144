import * as DefaultComponents from '../components';
import EventBus from './EventBus';

/**
 * Usage: <div data-components='{"Modal": {"a": 123}, "Cart": null}'></div>
 */

class ComponentManager {

    getComponentList(elem) {
        try {
            return JSON.parse(elem.getAttribute('data-components'));
        } catch (e) {
            console.error('data-components is in JSON format', e);
            return null;
        }
    }

    initiateComponents(elem, componentList) {
        for (const prop in componentList) {
            const options = componentList[prop];

            if (!DefaultComponents[prop]) {
                // Component will be loaded async

                import(`../components/${prop}`).then(module => {
                    let AsyncComponent = module.default;
                    new AsyncComponent(elem, options);

                    this.initializedComponents.push(prop);

                });
            } else {
                // Component is marked as default component
                const Component = DefaultComponents[prop];

                if (!Component) {
                    console.error(`${prop} is not a known component`);
                    return;
                }

                new Component(elem, options);

                this.initializedComponents.push(prop);
            }

        }
    }

    constructor() {

        this.initializedComponents = [];

        const elems = document.querySelectorAll('[data-components]');

        for (let i = 0; i < elems.length; i++) {
            const componentList = this.getComponentList(elems[i]);

            if (componentList) {
                this.initiateComponents(elems[i], componentList);
            }
        }

        EventBus.$emit('ComponentManagerReady');

    }
}

export default ComponentManager;
